<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        style="margin-right: 15px"
        type="primary"
        class="org back-btn"
        @click="
          $router.push({
            name: 'headquarters',
            params: {
              type: $route.params.type
            }
          })
        "
      >
        <i class="t-transition icons icon-chevron-left" />
        Назад
      </a-button>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="createDepartment()"
      >
        <a-icon type="plus" />{{ $t("Add") }}
      </a-button>
    </a-row>

    <a-row type="flex" class="table__filtrs">
      <h2>{{ title[$i18n.locale] }}</h2>
      <a-button
        :disabled="cardsIsEmpty"
        type="primary"
        style="margin: 0 0 15px auto"
        @click="removeItem(selectedPosts)"
      >
        <a-icon class="action-btns" type="delete" />
        Удалить
      </a-button>
    </a-row>

    <a-table
      :columns="columns"
      :loading="loading"
      :data-source="list.results"
      :pagination="false"
      :scroll="{ x: 1200, y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'departments-update',
              params: { id: item.id }
            })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="position" slot-scope="item">
        <a-input-number
          v-model="item.position"
          style="width: 70px"
          :min="1"
          @pressEnter="updatePosition(item.id, $event)"
        />
      </template>

      <template slot="card_title" slot-scope="item">
        <span
          class="text-center td-post-item"
          style="cursor: pointer; display: block; width: 100%; text-align: left"
          @click="
            $router.push({ name: 'departments-update', params: { id: item.id } })
          "
          >{{ item.title || "Не заполнено" }}
        </span>
      </template>

      <template slot="status" slot-scope="item">
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({ name: 'departments-update', params: { id: item.id } })
          "
        >
          <!--{{ $t("Edit") }}-->
          <a-icon type="edit" />
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <a-button type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+list.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      title: {
        oz: "Бўлимлар",
        uz: "Bo'limlar",
        ru: "Отделы",
        en: "Departments"
      },
      loading: false,
      list: {
        results: [],
        count: 0
      },
      perPage: 20,
      page: 1,
      offset: 0,
      columns: [
        {
          title: "ID",
          key: "id",
          width: "90px",
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("Order"),
          width: "90px",
          key: "position",
          fixed: "left",
          scopedSlots: { customRender: "position" }
        },
        {
          title: this.$t("TableTitle"),
          key: "card_title",
          width: "20%",
          scopedSlots: { customRender: "card_title" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: "20%",
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "20%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "20%",
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          // width: "100px",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      selectedRowKeys: [],
      selectedPosts: [],
      cardsIsEmpty: true
    }
  },
  created() {
    this.fetchData() // Fetch data when component is created
  },
  watch: {
    $route: {
      handler: "fetchData"
      // immediate: true
    }
  },

  methods: {
    toPage(arg) {
      this.$router.push({ query: { page: arg } })
    },
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.perPage
      try {
        let res = await this.$api.get(
          "/admin/common/activity/" + this.$route.params.act_id + "/children/",
          {
            params: {
              limit: this.perPage,
              offset: this.offset
            }
          }
        )
        if (res) {
          this.$set(this, "list", {
            results: (res && res.data.results) || [],
            count: res && res.data.count
          })
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }
      this.loading = false
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$api.delete(
              `/admin/common/activity/card/${i}/delete/`
            )
            if (res) {
              this.$message.success("Успешно удалено")
            }
          } catch (e) {
            this.$message.success("Ошибка при удалении")
            this.$sentry.captureMessage(e)
          }
        }
      } else {
        try {
          const res = await this.$api.delete(
            "/admin/common/activity/card/" + id + "/delete/"
          )
          if (res) {
            this.$message.success("Успешно удалено")
          }
        } catch (e) {
          this.$message.success("Ошибка при удалении")
          this.$sentry.captureMessage(e)
        }
      }
      await this.fetchData()
      this.selectedCards = []
      this.cardsIsEmpty = true
    },
    async createDepartment() {
      await this.$router.push({
        name: "departments-create",
        params: {
          // id: res && res.data && res.data.id,
          type: this.$route.params.type,
          act_id: this.$route.params.act_id
        }
      })
    },
    async updatePosition(itemId, event) {
      try {
        await this.$api.patch(`/admin/common/activity/card/${itemId}/update/`, {
          position: event.target.value
        })
        this.$message.success("Позиция обновлена")
        await this.fetchData()
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
